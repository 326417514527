<template>
  <FormWrapper
      :form="form"
      :hideForm="hideForm"
      :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    >
    <b-row>
      <b-col>
        <validation-observer
          :ref="`${form.id}-form`"
          v-slot="{ invalid }"
          v-if="canEdit"
        >
          <b-row>
            <b-col cols="10">
              <div class="form-group">
                <validation-provider
                  name="medicamento"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
                  <input
                    autocomplete="off"
                    id="medicamento"
                    v-model="fields.medicamento"
                    :readonly="!canEdit"
                    type="text"
                    class="form-control"
                    placeholder="Nome do medicamento"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  />
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="primary"
                block
                class="h-38 button"
                :disabled="invalid"
                @click="saveItem"
              >
                Adicionar
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col>
            <table class="table-eyecare">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) of form.value" :key="index">
                  <td style="width: 100%">
                    <span
                      style="margin-top: 5px; margin-right: 10px; float: left"
                      v-b-tooltip.hover :title="value?.medicamento?.length >= 120 ? value.medicamento : ''"
                    >
                      {{ value?.medicamento?.length >= 120 ? value.medicamento.slice(0, 120)+'...' : value.medicamento }}
                    </span>

                    <toggle-button
                      class="togle-button"
                      v-model="value.emUso"
                      :sync="true"
                      :height="24"
                      :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                      @change="handleUse(index, value.emUso)"
                      :disabled="!canEdit"
                    />
                    <span
                      class="medicine-status"
                      :class="{ enabled: value.emUso, disabled: !value.emUso }"
                      >{{ value.emUso ? 'Em uso' : 'Cessado' }}</span
                    >
                  </td>
                  <td class="text-right">
                    <div class="more-with-menu">
                      <MoreVertical class="more-icon" />
                      <div class="menu">
                        <b-button
                          variant="link remove mb-0 p-0"
                          :disabled="!canEdit"
                          @click="removeItem(index)"
                        >
                          Remover
                        </b-button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import MoreVertical from '@/assets/icons/more-vertical.svg'

export default {
  components: {
    FormWrapper,
    MoreVertical
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.outrosMedicamentosEmUso,
    })
  },
  data() {
    return {
      fields: {
        medicamento: null,
        emUso: true
      },
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/outrosMedicamentosEmUso', ['handleFields', 'handleProps']),
    saveItem() {
      if (this.form.value.some(v => v.medicamento.toLowerCase() === this.fields.medicamento.toLowerCase())) {
        this.$toast.warning('Medicamento já adicionado')
        return
      }

      const value = [...this.form.value]
      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)

      this.fields = {
        medicamento: null,
        emUso: true
      }
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    handleUse(index, emUso) {
      const value = [...this.form.value]
      value[index].emUso = emUso
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
    }
  },
}
</script>
<style lang="scss" scoped>
  .button {
    height: 38px;
    margin-bottom: 24px;
  }
</style>
